import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({
    providedIn: 'root',
})
export class BrowserNavigationService {
    isMobile: boolean;

    constructor(private platform: Platform, private iab: InAppBrowser) {
        this.platform.ready().then((source) => {
            this.isMobile = source === 'cordova';
        });
    }

    /**
     * In the browser loads the URL in the current browsing context.
     * In a mobile app loads the URL in the Cordova WebView if it is a whitelisted URL,
     * otherwise loads it in the InAppBrowser.
     * @param url - the URL to load
     */
    openTargetSelf(url: string) {
        this.open(url, '_self');
    }

    /**
     * In the browser loads the URL in a new tab (or window depending on the user settings).
     * In a mobile app loads the URL in the InAppBrowser.
     * @param url - the URL to load
     */
    openTargetBlank(url: string) {
        this.open(url, '_blank');
    }

    /**
     * In the browser loads the URL in a new tab (or window depending on the user settings).
     * In a mobile app loads the URL in the system default browser.
     * @param url - the URL to load
     */
    openTargetSystem(url: string) {
        this.open(url, '_system');
    }

    private open(url: string, target: string) {
        if (this.isMobile) {
            this.iab.create(url, target);
        } else {
            window.open(url, target);
        }
    }
}
